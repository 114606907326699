<template>
    <div class="MonitorDetailBaseBox">
        <loading v-if="isLoading" size="30px" style="height: 100%;"></loading>
        <div class="container" v-if="!isLoading">
            <van-row class="logRow">
                <van-col span="8">操作</van-col>
                <van-col span="6">操作人</van-col>
                <van-col span="10" style="text-align: right;">操作时间</van-col>
            </van-row>
            <scroll v-if="!isLoading&&detailList.length>0" class="wrapper" ref="scroll" :pullDownRefresh="pullDownRefreshObj" :pullUpLoad="pullUpLoadObj" :startY="parseInt(startY)" @pullingDown="onPullingDown" @pullingUp="onPullingUp">
                <div ref="wrapper">
                    <van-row v-for="(item,index) in detailList" :key="index" class="logList">
                        <van-col span="8">{{returnOptCode(item.optCode)}}</van-col>
                        <van-col span="6">{{returnPerson(item.opCtId)}}</van-col>
                        <van-col span="10" style="text-align: right;">{{item.createDate}}</van-col>
                    </van-row>
                </div>
            </scroll>
            <nodata title="暂无数据" img="noCustomer" v-if="detailList.length==0" style="height: 100%;"></nodata>
        </div>
    </div>
</template>

<script>
import BScroll from 'better-scroll'
import Scroll from '@/components/Scroll/index'
import titleMixin from '@/mixin/title'
export default {
    name: 'MonitorDetail',
    title: '操作日志',
    mixins: [titleMixin],
    data() {
        return {
            pullDownRefresh: true,
            pullDownRefreshThreshold: 60,
            pullDownRefreshStop: 60,
            pullUpLoad: true,
            pullUpLoadThreshold: 0,
            pullUpLoadMoreTxt: '加载更多',
            pullUpLoadNoMoreTxt: '没有更多数据了',
            show: false,
            routerParams: {},
            page: {
                pageN: 1,
                pageSize: 10,
                total: 0
            },
            isLoading: false,
            detailList: [],
            scroll: null,
            startY: 0,
            personnelList: []
        }
    },
    async created() {
        this.reGetList()
    },
    computed: {
        pullDownRefreshObj: function () {
            return this.pullDownRefresh ? {
                threshold: parseInt(this.pullDownRefreshThreshold),
                stop: parseInt(this.pullDownRefreshStop)
            } : false
        },
        pullUpLoadObj: function () {
            return this.pullUpLoad ? {
                threshold: parseInt(this.pullUpLoadThreshold),
                txt: { more: this.pullUpLoadMoreTxt, noMore: this.pullUpLoadNoMoreTxt }
            } : false
        }

    },
    mounted() {
        this.setMenu()
    },
    methods: {
        returnOptCode(optCode) {
            if (optCode == 'restart') {
                return '重启收件'
            } else if (optCode == 'init') {
                return '切换为初始化'
            } else if (optCode == 'switchChannel') {
                return '切换邮件服务器'
            } else if (optCode == 'markSolved') {
                return '已处理'
            }
        },
        returnPerson(opCtId) {
            let filterArr = this.personnelList.filter(item => item.ctId == opCtId)
            if (filterArr.length > 0) {
                return filterArr[0].realName
            } else {
                return ''
            }
        },
        async getPersonnel() {
            let data = {
                dataType: 'contact',
                funType: 'all'
            }
            await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.accountDropList_get, { params: data }).then((res) => {
                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    this.personnelList = res.data.data
                    // console.log(this.personnelList, '++')
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }, (res) => {
                this.$toast.fail(this.Global.config.errorTitle)
            })
        },
        setScroll() {
            this.$nextTick(() => {
                if (!this.scroll) {
                    this.scroll = new BScroll(this.$refs.wrapper, {
                        click: true,
                        probeType: 3
                    })
                } else {
                    this.scroll.refresh()
                }
            })
        },
        setMenu() {
            this.Global.utils.rightMenu.clearMenu()
        },
        async onPullingDown() {
            this.page.pageN = 1
            this.detailList = await this.getDetailList()
            if (this._isDestroyed) {
                return
            }
            this.$nextTick(() => {
                this.$refs.scroll.forceUpdate()
            })
        },
        rebuildScroll() {
            this.$nextTick(() => {
                this.$refs.scroll.destroy()
                this.$refs.scroll.initScroll()
            })
        },
        async onPullingUp() {
            if (this.detailList.length >= this.page.total) {
                this.$refs.scroll.forceUpdate(false)
                return
            }
            if (this.detailList.length < this.page.total) {
                this.page.pageN += 1
                let list = await this.getDetailList()
                this.detailList = this.detailList.concat(list)
                this.$nextTick(() => {
                    if (this.detailList.length >= this.page.total) {
                        this.$refs.scroll.forceUpdate(false)
                    } else {
                        this.$refs.scroll.forceUpdate(true)
                    }
                })
            }
        },
        async getDetailList(type) {
            this.routerParams = this.$route.query
            let listArr = []
            if (JSON.stringify(this.routerParams) != '{}') {
                try {
                    let url = this.Global.config.apiBaseURL + this.Global.api.v2.mailWatcher_taskOperateLog
                    let params = {
                        corpId: this.routerParams.corpId,
                        mailAccount: this.routerParams.mailAccount,
                        pageN: this.page.pageN,
                        pageSize: this.page.pageSize,
                        days: this.routerParams.days
                    }
                    let res = await this.axios.get(url, { params })
                    if (res.data.code.toString() == this.Global.config.RES_OK && this.Global.utils.isObject(res.data.data)) {
                        listArr = res.data.data.dataList
                        this.page.total = res.data.data.totalNum
                    } else {
                        this.$toast.fail(res.data.msg)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
            return listArr
        },
        async reGetList() {
            this.page.pageN = 1
            this.isLoading = true
            await this.getPersonnel()
            this.detailList = await this.getDetailList()
            this.isLoading = false
        }
    },
    watch: {
        async $route() {
            if (this.$route.path == '/mailmonitor/tasklog') {
                this.reGetList()
            }
        },
        pullDownRefreshObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullDown()
                } else {
                    scroll.closePullDown()
                }
            },
            deep: true
        },
        pullUpLoadObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullUp()
                } else {
                    scroll.closePullUp()
                }
            },
            deep: true
        },
        startY() {
            this.rebuildScroll()
        }
    },
    components: {
        'scroll': Scroll
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
